<template>
  <div>
    <b-overlay
      :show="$apollo.loading"
      rounded="sm"
      spinner-variant="primary"
    >
      <calendar
        v-if="!$apollo.loading"
        :start-date="begin_date"
      />
    </b-overlay>
  </div>
</template>
<script>
import Calendar from '@/views/incubator-portal/programs/components/calendar/Calendar.vue'
import gql from 'graphql-tag'

export default {
  components: {
    Calendar,
  },
  data() {
    return {
      begin_date: null,
    }
  },
  apollo: {
    begin_date: {
      query() {
        return gql`{
          programs_basicinfo_by_pk(id: "${this.$route.params.id}" ){
            begin_date
          }
        }`
      },
      update: data => data.programs_basicinfo_by_pk.begin_date,
    },
  },
}
</script>
