import axios from '@axios'
import gql from 'graphql-tag'
import { newApolloClient } from '@/vue-apollo'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'primary',
        label: 'Phases',
      },
      {
        color: 'warning',
        label: 'SubPhases',
      },
      {
        color: 'success',
        label: 'Activities',
      },
    ],
    selectedCalendars: ['Phases', 'Activities', 'SubPhases'],
    data: [],
  },
  getters: {},
  mutations: {
    SET_DATA(state, val) {
      state.data = val
    },
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, { calendars }) {
      return new Promise(resolve => {
        newApolloClient.query({
          query: gql`{  programs_basicinfo_by_pk(id: ${router.currentRoute.params.id}) {
              id
              programs_operationstables(where: {operations_type: {_ilike: "Phase"}}) {
                  id
                  title
                  description
                  location
                  status
                  begindate_timestamp
                  enddate_timestamp
                  programs_operationstables(where: {operations_type: {_ilike: "SubPhase"}}) {
                      id
                      title
                      begindate_timestamp
                      enddate_timestamp
                      programs_operationstables {
                          id
                          title
                          begindate_timestamp
                          enddate_timestamp
                      }
                  }
              }
          }
          }`,
        })
          .then(result => {
            this.state.data = []
            result.data.programs_basicinfo_by_pk.programs_operationstables.forEach(phase => {
              if (calendars.includes('Phases')) {
                this.state.data.push({
                  id: phase.id,
                  url: '',
                  title: phase.title,
                  start: new Date(phase.begindate_timestamp),
                  end: new Date(phase.enddate_timestamp),
                  priority: 1,
                  allDay: false,
                  extendedProps: {
                    description: phase.description,
                    location: phase.location,
                    status: phase.status,
                    calendar: 'Phases',
                  },
                })
              }
              phase.programs_operationstables.forEach(subphase => {
                if (calendars.includes('SubPhases')) {
                  this.state.data.push({
                    id: subphase.id,
                    url: '',
                    title: subphase.title,
                    start: new Date(subphase.begindate_timestamp),
                    end: new Date(subphase.enddate_timestamp),
                    priority: 2,
                    allDay: false,
                    extendedProps: {
                      description: subphase.description,
                      location: subphase.location,
                      status: subphase.status,
                      calendar: 'SubPhases',
                    },
                  })
                }
                if (calendars.includes('Activities')) {
                  subphase.programs_operationstables.forEach(activity => {
                    this.state.data.push({
                      id: activity.id,
                      url: '',
                      title: activity.title,
                      start: new Date(activity.begindate_timestamp),
                      end: new Date(activity.enddate_timestamp),
                      priority: 3,
                      allDay: false,
                      extendedProps: {
                        description: activity.description,
                        location: activity.location,
                        status: activity.status,
                        calendar: 'Activities',
                      },
                    })
                  })
                }
              })
            })
            ctx.commit('SET_DATA', this.state.data)
            resolve(this.state)
          })
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise(resolve => {
        newApolloClient.mutate({
          mutation: gql`mutation {
              update_programs_operationstable_by_pk(pk_columns: {id: ${event.id}}, _set: {title: "${event.title}", status: "${event.extendedProps.status}", begindate_timestamp: "${new Date(event.start).toISOString()}", enddate_timestamp: "${new Date(event.end).toISOString()}", location: "${event.extendedProps.location}", description: "${event.extendedProps.description}"}) {
                  id
                  title
                  begindate_timestamp
                  enddate_timestamp
                  status
                  location
                  description
              }
          }`,
        })
          .then(response => {
            resolve({
              event: {
                id: response.data.update_programs_operationstable_by_pk.id,
                title: response.data.update_programs_operationstable_by_pk.title,
                start: new Date(response.data.update_programs_operationstable_by_pk.begindate_timestamp),
                end: new Date(response.data.update_programs_operationstable_by_pk.enddate_timestamp),
                extendedProps: {
                  location: response.data.update_programs_operationstable_by_pk.location,
                  description: response.data.update_programs_operationstable_by_pk.description,
                  status: response.data.update_programs_operationstable_by_pk.status,
                },
              },
            })
          })
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
